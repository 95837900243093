import React from "react";
import _ from "../../../i18n";

/**
 *
 * @param string majorKey - Id to be selected if either all other elements that might be selected or no element is being selected
 * @param array configState - array contains elements to show in the filter, its format should look like
 *              {id: "myId", label: "myLabel", color: "myColor", count: "number of items which is iptional"}
 * @param array selectedState - An array represents the items have been currently selected, it contains only ids.
 *              If multiSelect is false, there is only one item selected
 * @param boolean multiSelect - By default assigned to false. It determines whether the filter supports one or multiple choices
 * @function handleStateChange - A function that triggers any change occured when a certain item is selected
 *
 * @returns JS Element contains designed states that could be filtered
 */
const StateFilter = ({
  majorKey,
  configState,
  handleStateChange,
  selectedState,
  multiSelect = false,
}) => {
  const onStateChange = (selected) => {
    const selectedIndex = selectedState.indexOf(selected);

    if (selected === majorKey && selectedIndex !== -1) {
      return;
    }

    if (!multiSelect) {
      handleStateChange([selected]);
      return;
    }

    handleStateChange(
      selected !== majorKey || selectedIndex !== -1
        ? selectedIndex !== -1
          ? selectedState.length !== 1
            ? selectedState.filter(
                (item, index) => index !== selectedIndex && item !== majorKey
              )
            : [majorKey]
          : selectedState.length + 2 !== configState.length
          ? [...selectedState, selected].filter((item) => item !== majorKey)
          : [majorKey]
        : [selected]
    );
  };

  return (
    <div className="filter_container">
      {configState.map((item) => {
        const isSelected = selectedState.indexOf(item.id) !== -1;
        return (
          <span
            key={item.id}
            onClick={() => onStateChange(item.id)}
            className={!isSelected ? "state_hover" : ""}
            style={{
              borderColor: item.color,
              color: isSelected ? "white" : "#3c4e64",
              backgroundColor: isSelected ? item.color : "#e6e6fa82",
            }}
          >
            {item.count !== undefined && (
              <p
                className="count"
                style={{
                  borderColor: item.color,
                  color: item.color,
                }}
              >
                {item.count}
              </p>
            )}
            <p>{_(item.label)}</p>
          </span>
        );
      })}
    </div>
  );
};

export default StateFilter;
