import React, { useState, Fragment, useEffect } from "react";
import { useQuery } from "react-query";
import moment from "moment";

import { getTimeSheetProgression, getCollaboratorsAvatars } from "../../../api";
import _ from "../../../i18n";

import { Table, Tooltip } from "antd";
import TTPPaginator from "../../common/TTPPaginator";
import { TiArrowSortedDown, TiArrowSortedUp, TiWarning } from "react-icons/ti";
import TimeSheetProgressionFilter from "./TimeSheetProgressionFilter";
import "./TimeSheetProgression.local.scss";
import classNames from "classnames";

const columns = [
  {
    title: "",
    dataIndex: "name",
    key: "name",
  },
  {
    title: _("budgetedHours"),
    dataIndex: "budget",
    key: "budget",
    width: "100px",
  },
  {
    title: _("realBillableHours"),
    dataIndex: "realBillable",
    key: "realBillable",
    width: "100px",
  },
  {
    title: _("realNonBillableHours"),
    dataIndex: "realNonBillable",
    key: "realNonBillable",
    width: "100px",
  },
  {
    // title: _("gap"),
    title: 'Écart avec le budget',
    dataIndex: "gap",
    key: "gap",
    width: "100px",
  },
  {
    // title: _("cp"),
    title: 'Temps supplémentaire',
    dataIndex: "cp",
    key: "cp",
    width: "15%",
  },
];

const TimeSheetProgression = ({ token, navCommunity, language, hasAccess }) => {
  const [progression, setProgression] = useState([]);
  const [nbResult, setNbResult] = useState([]);
  const [avatars, setAvatars] = useState([]);
  const [filter, setFilter] = useState({
    year: new Date().getFullYear().toString(),
    collaborators: [],
    clients: [],
    zone: null,
    role: null,
    bu: null,
    paginationPage: 1,
    pageSize: 10,
    sort: null,
  });

  let timesheetQuery = useQuery(
    [("getTimeSheetProgression", token, navCommunity, filter)],
    async () => {
      if (token && navCommunity) {
        try {
          const response = await getTimeSheetProgression(
            navCommunity.id,
            token,
            filter
          );
          setProgression(response.data.data.data);
          setNbResult(response.data.data.nbrResult);
        } catch (e) {
          console.error(e);
          return null;
        }
      }
    }
  );

  let avatarsQuery = useQuery(
    ["getCollaboratorAvatars", token, navCommunity],
    async () => {
      if (token && navCommunity) {
        try {
          const response = await getCollaboratorsAvatars(
            navCommunity.id,
            token
          );
          setAvatars(response.data.data);
        } catch (e) {
          return null;
        }
      }
    }
  );

  const renderTimeSheetTree = () => {
    let columnsTest = [];

    columnsTest = [
      {
        title: () => {
          return (
            <span class="ant-table-header-column">
              <div style={{ height: 43, textAlign: "right" }}>
                <span
                  class="ant-table-column-title"
                  style={{ width: "99.5%" }}>
                  SYNTHETIQUE
                </span>
              </div>
            </span>
          );
        },
        dataIndex: "label",
        key: "label",
        align: 'left',
        render: (text, record) => {
          return (
            <div
              className={"balance-sheet-key-indicator-treasury-bold"}
              style={{
                minHeight: record.type === "COLLABORATOR" ? 72 : 43,
                width: '99.5%'
              }}>
              <div
                style={{
                  width: '100%'
                }}
                className={
                  record.type === "CLIENT" && "ant-table-column-title_sub"
                }>
                <div  className="timesheet-table-title">
                  <p
                    className={
                      classNames({
                        'timesheet-table-client-type-padding': record.type === 'CLIENT_TYPE',
                        'timesheet-table-client-name-padding': record.type === 'CLIENT',
                        'timesheet-table-category-padding': record.type === 'CATEGORY',
                      })
                    }
                  >
                    {record.name && record.name.length >= 46
                      ? `${record.name.slice(0, 32)}...`
                      : record.name}
                  </p>

                  {record.type === "CLIENT_TYPE" && (
                    <span className="timesheet-table-client-type-count-badge">
                      {record.count}
                    </span>
                  )}
                </div>
                {record.type === "CLIENT" && record.role && (
                  <span style={{ marginRight: '12px', padding: '2px 12px' }}>
                    {record.role}
                  </span>
                )}
              </div>
            </div>
          );
        },
        width: 350,
      },
    ];

    for (let i = 1; i < columns.length; i++) {
      columnsTest.push({
        title: () => {
          return (
            <span class="ant-table-header-column">
              <div style={{ height: 43 }}>
                <span class="ant-table-column-title">
                  {_(columns[i].title)} {columns[i].key === "gap" ? "" : "(h)"}
                  <span
                    class="sorter"
                    style={{
                      color:
                        filter.sort && filter.sort.includes(columns[i].key)
                          ? "#e4525d"
                          : "#fff",
                    }}
                    onClick={() => {
                      if (!filter.sort) {
                        setFilter({
                          ...filter,
                          sort: `${columns[i].key}_desc`,
                        });
                      } else {
                        setFilter({
                          ...filter,
                          sort:
                            filter.sort === `${columns[i].key}_desc`
                              ? `${columns[i].key}_asc`
                              : `${columns[i].key}_desc`,
                        });
                      }
                    }}>
                    {filter.sort === `${columns[i].key}_asc` ? (
                      <TiArrowSortedUp />
                    ) : (
                      <TiArrowSortedDown />
                    )}
                  </span>
                </span>
              </div>
            </span>
          );
        },
        dataIndex: columns[i].dataIndex,
        key: columns[i].key,
        render: (text, record) => {
          const isCollaboratorRow = record.type === 'COLLABORATOR';
          const isClientTypeRow = record.type === 'CLIENT_TYPE';
          const isClientRow = record.type === 'CLIENT';
          const isCategoryRow = record.type === 'CATEGORY';

          let budgetedHours = record.budget;
          let realHours = record.realBillable + record.realNonBillable;
          let nbrWorkingDays = moment().diff(moment([filter.year, 0, 1]), "days") + 1;
          let budgetedHoursUntilNow = (budgetedHours * nbrWorkingDays) / 365;
          let gapWithBudget = "";

          if (!isClientTypeRow) {
            // budgetedHours = record.budget;
            // realHours = record.realBillable + record.realNonBillable;
            // nbrWorkingDays = moment().diff(moment([filter.year, 0, 1]), "days") + 1;
            // budgetedHoursUntilNow = (budgetedHours * nbrWorkingDays) / 365;

            gapWithBudget = (realHours - budgetedHoursUntilNow).toFixed(2);
          }


          return columns[i].key === "cp" ? (
            record.cp_config === "NO_CONFIGURATION" ? (
              <span
                className={"balance-sheet-td-component"}
                style={{ minHeight: record.type === "COLLABORATOR" ? 72 : 43 }}>
                <Tooltip
                  key={`role-${record.key}`}
                  placement="top"
                  title={_("regimeNotConfigurated")}>
                  <div className="tag" style={{ color: "red", marginTop: 10 }}>
                    <TiWarning />
                  </div>
                </Tooltip>
              </span>
            ) : (
              <span
                className={"balance-sheet-td-component"}
                style={{ minHeight: record.type === "COLLABORATOR" ? 72 : 43 }}>
                <div>
                  <span
                    className={
                      text && text.length >= 46
                        ? "balance-sheet-label-wrap"
                        : ""
                    }>
                    <div
                      className={classNames({
                        'timesheet-table-balance-is-negative': record.gap < 0
                      })}
                    >
                      {record.type === "CLIENT_TYPE" ? "" : (
                        record.gap + (record.type === "COLLABORATOR" ? "%" : "")
                      )}
                    </div>
                  </span>
                  {record.type === "COLLABORATOR" && (
                    <div>
                      <Tooltip
                        key={`role-${record.key}`}
                        placement="top"
                        title={_("cp")}>
                        <span className={"balance-sheet-td-percentage"}>
                          <span className={"percentage__positive"}>
                            <span>
                              {parseFloat(parseFloat(record.cp).toFixed(2))}h
                            </span>
                          </span>
                        </span>
                      </Tooltip>
                      <Tooltip
                        key={`role-${record.key}`}
                        placement="top"
                        title={_("realized")}>
                        <span className={"balance-sheet-td-percentage"}>
                          <span className={"percentage__positive"}>
                            <span>
                              {parseFloat(realHours).toFixed(2)}h
                            </span>
                          </span>
                        </span>
                      </Tooltip>
                    </div>
                  )}
                </div>
              </span>
            )
          ) : columns[i].key === "gap" ? (
            <span
              className={"balance-sheet-td-component"}
              style={{ minHeight: record.type === "COLLABORATOR" ? 72 : 43 }}>
              <div>
                <span
                  className={classNames({
                      "balance-sheet-label-wrap": text && text.length >= 46,
                      "timesheet-table-balance-is-negative": !isClientTypeRow && gapWithBudget < 0
                    })
                  }>
                  <div>
                    {!isClientTypeRow && gapWithBudget + 'h'}
                  </div>
                </span>
                {record.type === "COLLABORATOR" && (
                  <div>
                    <Tooltip
                      key={`role-${record.key}`}
                      placement="top"
                      title={_("proportionnalBudget")}>
                      <span className={"balance-sheet-td-percentage"}>
                        <span className={"percentage__positive"}>
                          <span>
                            {parseFloat(budgetedHoursUntilNow).toFixed(2)}h
                          </span>
                        </span>
                      </span>
                    </Tooltip>
                    <Tooltip
                      key={`role-${record.key}`}
                      placement="top"
                      title={_("realized")}>
                      <span className={"balance-sheet-td-percentage"}>
                        <span className={"percentage__positive"}>
                          <span>
                            {parseFloat(realHours).toFixed(2)}h
                          </span>
                        </span>
                      </span>
                    </Tooltip>
                  </div>
                )}
              </div>
            </span>
          ) : (
            <span
              className={"balance-sheet-td-component"}
              style={{
                minHeight: record.type === "COLLABORATOR" ? 72 : 43,
                padding:
                  record.type === "COLLABORATOR"
                    ? "20px 0 20px 0px"
                    : "10px 0 10px 0px",
              }}>
              <span
                className={
                  text && text.length >= 46 ? "balance-sheet-label-wrap" : ""
                }>
                <div>
                  {record.type === 'CLIENT_TYPE' ? "" : record[columns[i].dataIndex] + "h" }
                </div>
              </span>
            </span>
          );
        },
        width: "180px",
      });
    }

    return (
      <div style={{ paddingTop: "0rem" }}>
        <div className={"rmodal__center"}>
          <Table
            columns={columnsTest}
            dataSource={progression}
            pagination={false}
            bordered={false}
            style={{ margin: "0 0 0 0" }}
            className="timesheet-progression-table"
            indentSize={14}
          />
        </div>
      </div>
    );
  };

  const renderPaginate = () => {
    return (
      <TTPPaginator
        onPageChange={({ selected }) =>
          setFilter({ ...filter, paginationPage: selected + 1 })
        }
        pageSize={10}
        nbResult={nbResult}
        paginationPage={filter.paginationPage}
      />
    );
  };

  return (
    <Fragment>
      { hasAccess === "1" ? (
        <div className="portfolio_container">
        <div className="container">
          <TimeSheetProgressionFilter
            filterFormIsVisible={false}
            filter={filter}
            nbResult={nbResult}
            updateFilter={setFilter}
            fetching={timesheetQuery.isFetching}
          />
          <div className="medium-11">
            {timesheetQuery.isFetching ? (
              <div className="ripple_loading">
                <img src={"/img/Ripple-1s-150px.svg"} alt="" />
              </div>
            ) : Object.keys(progression).length ? (
              <Fragment>
                {renderTimeSheetTree()}
                {renderPaginate()}
              </Fragment>
            ) : (
              <div className="no_data">
                <div className="title">{_("noDataFound")}</div>
                <div className="subTitle">{_("noClientsFound")}</div>
              </div>
            )}
          </div>
        </div>
      </div>
      ) : ""}
    </Fragment>
  );
};

export default TimeSheetProgression;
