import React from "react";
import StateFilter from "../../common/StateFilter";

const BudgetStateFilter = ({
  majorKey,
  configState,
  setFilters,
  filters,
  selectedState,
  // collaborators,
  // collaboratorsFetching,
  // collaboratorsFetched,
  // handleFetchCollaborators,
  // handleSelectedCollaborator,
}) => {
  const handleStateChange = (selected) => {
    setFilters({ ...filters, budgetState: selected });
  };

  return (
    <div className="filter_container">
      <StateFilter
        majorKey={majorKey}
        configState={configState}
        handleStateChange={handleStateChange}
        selectedState={selectedState}
        multiSelect={true}
      />
      
      {/* <CollaboratorList
        collaborators={collaborators}
        selectedCollaborator={handleSelectedCollaborator}
        collaboratorsFetching={collaboratorsFetching}
        collaboratorsFetched={collaboratorsFetched}
        handleFetchCollaborators={handleFetchCollaborators}
      /> */}
    </div>
  );
};

export default BudgetStateFilter;
